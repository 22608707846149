<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" style="justify-items: center">
            <v-btn class="button" @click="print">print</v-btn>
            <div v-for="each in redeem_data" :key="each.id">
              <div id="printMe" style="height:297mm; width:210mm; margin-top: 12px;">
                <div class="headerbox">
                  <h2
                    style="text-align: center; width: 100%;"
                  >แบบฟอร์มแลกรับของรางวัล / Redemption Form {{ currentYear }}</h2>

                  <!-- <div class="flex-grow-1"></div> -->
                </div>

                <div class="headerbox2">
                  <h4 style="text-align: center; width: 100%; color: red">
                    ***การจัดส่งแบบ พนักงานขายเป็นผู้ส่งมอบ ได้แก่ สินค้าประเภท ทองคำ, บัตรกำนัล, กระเป๋าแบรนด์เนม, ผลิตภัณฑ์บริษัทฯ และอื่นๆ***
                    <br />***การจัดส่งแบบ Door to Door ได้แก่ สินค้าประเภท เครื่องใช้ไฟฟ้า, มือถือ, ไอที, เครื่องมือทางการแพทย์***
                  </h4>

                  <!-- <div class="flex-grow-1"></div> -->
                </div>

                <div>
                  <div style="display: flex; justify-content: flex-end;">
                    <h4 style="margin-right: 8px;">ชื่อร้าน / Agent / Sub Agent</h4>
                    <div
                      style="border: solid; border-width: 1px; width: 400px; height: 24px"
                    >{{each.shop && each.shop.name}}</div>
                  </div>

                  <div style="display: flex; justify-content: flex-end;">
                    <h4 style="margin-right: 8px;">รหัส / Agent / Sub Agent ID</h4>
                    <div
                      style="border: solid; border-width: 1px; width: 400px; height: 24px"
                    >{{each.shop && each.shop.code}}</div>
                  </div>

                  <h4>
                    ข้าพเจ้ามีความประสงค์ใช้คะแนน (Point) แลกของรางวัล หรือใช้คะแนน (Point) บวกเงิน
                    <span
                      style="color: red"
                    >(กรณีเพิ่มเงินใช้ได้กับแลกทัวร์เท่านั้น)</span>
                    ดังนี้
                  </h4>

                  <div style="display: flex; margin-left: 32px;">
                    <div
                      style="border: solid; border-width: 1px; width: 24px; height: 24px; margin-right: 8px;"
                    ></div>
                    <h4 style="color: red">Agent Point</h4>
                  </div>

                  <div style="display: flex; margin-bottom: 8px; margin-left: 32px;">
                    <div
                      style="border: solid; border-width: 1px; width: 24px; height: 24px; margin-right: 8px;"
                    ></div>
                    <h4 style="color: red">Chang Family (Sub Agent)</h4>
                  </div>

                  <table style="width:100%; margin-bottom: 8px;">
                    <tr>
                      <th rowspan="2">
                        รหัสของรางวัล
                        <br />Rewards Code
                      </th>
                      <th rowspan="2">
                        รายการของรางวัล
                        <br />Product Description
                      </th>
                      <th colspan="2">เลือกประเภทของการจัดส่ง</th>
                      <th rowspan="2">
                        จำนวน
                        <br />Quantity
                      </th>
                      <th rowspan="2">
                        แลกด้วยคะแนน
                        <br />Point Only
                      </th>
                      <th colspan="2">
                        แลกด้วยคะแนน + เงิน
                        <br />
                        <span style="color: red">(กรณีเพิ่มเงินใช้ได้กับแลกทัวร์เท่านั้น)</span>
                      </th>
                    </tr>
                    <tr>
                      <td style="color: red">Door to Door</td>
                      <td style="color: red">พนักงานส่ง</td>
                      <td>คะแนน / Point</td>
                      <td>บาท / Baht</td>
                    </tr>
                    <tr style="height: 72px;">
                      <!-- <div > -->
                      <td>{{each.rewardCode}}</td>
                      <td>{{each.rewardTitle}}</td>
                      <td></td>
                      <td></td>
                      <td>{{each.quantity}}</td>
                      <td>{{each.totalPoint}}</td>
                      <td></td>
                      <td></td>
                      <!-- </div> -->
                    </tr>
                  </table>

                  <u style="color: black">รายละเอียดร้านค้า</u>

                  <!-- <br/> -->

                  <h4 style>
                    ชื่อร้านค้า Agent / Sub Agent
                    <u style="font-weight: bold;">{{shopUserMaster(each, each.shop && each.shop.shopUsers).name}}</u>
                  </h4>

                  <!-- <h4 style>
                    คะแนนสะสม / Point
                    <u style="font-weight: bold;">{{each.remain_point}}</u> ณ วันที่
                    <u style="font-weight: bold;">{{changeFormat(each.createtime)}}</u>
                  </h4> -->

                  <h4
                    style
                  >ชื่อเอเย่นต์ / Agent (กรณีที่ผู้แลกเป็นร้าน Sub Agent โปรดระบุ Agent)....................................................................................................</h4>

                  <u style="color: black">สถานที่จัดส่ง / Product Deliverry Address</u>

                  <h4
                    style="color: red"
                  >*** ผู้รับสินค้าเองเขียนชื่อตัวบรรจง ผู้รับสินค้าแทนให้แนบสำเนาบัตรประชาชน กับขนส่งทุกครั้ง***</h4>

                  <h4 style>
                    ชื่อ - นามสกุล ของผู้รับของรางวัล / Name
                    <u
                      style="font-weight: bold;"
                    >{{shopUserMaster(each, each.shop && each.shop.shopUsers).name}}</u>
                  </h4>

                  <h4 style>
                    หมายเลขโทรศัพท์ที่ติดต่อได้ / Contact Telephone Number
                    <u
                      style="font-weight: bold;"
                    >{{each.shop && each.shop.phone_number}}</u>
                  </h4>

                  <h4 style>
                    ที่อยู่จัดส่ง:
                    <u style="font-weight: bold;">{{each.shop && each.shop.address}}</u>
                  </h4>

                  <h5
                    style
                  >**ข้าพเจ้าตกลงผูกพันและยอมรับตามข้อกำหนดและเงื่อนไขของรายการ "Agent Point" และ "Chang Family" และตกลงให้บริษัทฯ ส่งมอบชื่อ - นามสกุล ที่อยู่ เบอร์โทรศัพท์</h5>

                  <h5
                    style
                  >ให้แก่ผู้จัดส่งสินค้าหรือบริการ ตามที่ข้าพเจ้าได้ใช้สิทธิ์แลกรับของรางวัล ไว้ในแบบฟอร์มนี้</h5>

                  <h5 style>**โปรดตรวจสอบข้อกำหนดและเงื่อนไขได้ที่เจ้าหน้าที่ขาย ที่ดูแลท่าน</h5>

                  <h5
                    style
                  >**I agree and accept to be bound to terms and condotions of the "Agent Point" and "Chang Family" program and anthorize Chang International Co.,Ltd. To disclose</h5>

                  <h5
                    style
                  >my name, address and telephonenumber to the respective third party as indicated in this redemption form.</h5>

                  <h5 style>**Please see terms and conditions of redemtion with your sale staff</h5>

                  <h4
                    style="text-align: right;"
                  >(..................................{{shopUserMaster(each, each.shop && each.shop.shopUsers).name}}..................................)</h4>

                  <h4
                    style="text-align: right;"
                  >ลายมือชื่อผู้แลกรับของรางวัล / Signature of redeemable person</h4>

                  <h4
                    style="text-align: right;"
                  >วันที่ / Date {{formatDate(each.CreatedAt)}}</h4>
                </div>

                <div class="footerbox">
                  <u style="color: black">สำหรับเจ้าหน้าที่</u>

                  <h4>วันที่รับเอกสาร..................................... ชื่อผู้ดูแลลูกค้า .............................................. ผู้บันทึกข้อมูล ...........................................</h4>

                  <!-- <div class="flex-grow-1"></div>
                  <v-btn color="primary">Login</v-btn>-->
                </div>
              </div>

              <div class="pagebreak"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
moment.locale("th");

export default {
  name: "RedeemForm",
  components: {},
  computed: {
    currentYear() {
      return moment().year()
    }
  },
  data() {
    return {
      email: "",
      dialog_loading: false,
      redeem_data: [],
      user: this.$user
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.getData();
    },
    changeFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getData() {
      const type = this.$route.query.type
      const count = this.$route.query.count
      const params = this.$route.query.params

      if(type === 'item' || type === 'select') {
        const json = localStorage.getItem(type === 'item' && "item_transaction" || "selected_transaction")
        this.redeem_data = this.$parseJson(json)
      } else {
        const { default: Redeemed } = await import("@/store/models/redeemed");
        const data = this.$parseJson(params)
        data.params.perPage = count + 50

        Redeemed.gets(data)
          .then(response => {
            this.redeem_data = (response && response.data) || [];
          })
          .catch(e => {
            console.error(e.response);
          });
      }
    },
    shopUserMaster(item, users) {
      if(!item.master) {
        let master = users.find((e) => e.type === 'master')

        if(!master) {
          master = {
            name: item.shop && item.shop.name
          }
        }

        item.master = master
      }
      
      return item.master
    },
    print() {
      // Pass the element id here
      // this.$htmlToPaper("printMe");
      window.print();
    }
  }
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

table,
tr,
td,
th {
  border: 1px solid black;
}

@page {
  size: A4;
}

@media print {
  /* styles go here */

  #printMe {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  .pagebreak {
    page-break-after: always;
  } /* page-break-after works, as well */

  button {
    display: none;
  }
  .headerbox {
    /* background-color: #a2c1e3;
    margin-bottom: 0px;
    padding: 12px; */
    -webkit-print-color-adjust: exact;
  }

  .headerbox2 {
    /* background-color: #ddebf7;
    padding: 12px; */
    -webkit-print-color-adjust: exact;
  }

  .footerbox {
    background-color: #e2efda;
    padding: 20px;
    -webkit-print-color-adjust: exact;
  }
  /* .container #content #main {
    width: 90%;
    margin: 0px;
    padding: 0px;
  } */
  /* * {
    color: #000;
    background-color: #fff;
    @include box-shadow(none);
    @include text-shadow(none);
  } */
}

.headerbox {
  background-color: #a2c1e3 !important;
  margin-bottom: 0px;
  padding: 12px;
}

.headerbox2 {
  background-color: #ddebf7;
  padding: 12px;
}

.footerbox {
  background-color: #e2efda;
  padding: 20px;
}

td:empty:after {
  content: "\00a0"; /* HTML entity of &nbsp; */
}
</style>
